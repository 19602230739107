#Navbar_Links{
    margin-right: 2rem;
    display: flex;
    justify-content: center;
}
.Navbar_Link{
    font-weight: 700;
    font-family: sans-serif;
    font-size: 1rem;
}
.header_Body{
    background-color: #FBF5F1;
}
.search_bar{
    background-color: #DDC7BB;
    border-radius: 10px;
    padding-bottom: 1rem;
}