.choice_Block{
    background-color: #DDC7BB;
    height: 268px;
    width: 275px;
    border-radius: 10px;
}
.choice_Block h3{
    font-family:sans-serif;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    font-weight: 700;
}
.choice_Block p{
    margin-left: 0.5rem;
    margin-top: 1.2rem;
    font-weight: 700;
}
.Icons{
    background-color: #FBF5F1;
    border-radius: 10px;
    margin-top: 1rem;
    margin-left: 0.5rem;
    width: 4rem;
    height: 4rem;
    text-align: center;
    align-content: center;
}
.rooms_Details{
    width: 382px;
    height: 534px;
    border-radius: 10px;
    background-color:#DDC7BB;
}
.review_Box{
    background-color:#FBF5F1;
}
.review_Box h6{
    color: #2B1B12;
    font-family: monospace;
}
.review_line{
   white-space: nowrap;
   overflow: hidden;
   position: relative;
}
@keyframes slideAnimation {
    0%{
        transform: translateX(200%);
    }
    100%{
        transform: translateX(-1600%);
    }
    
}
.reviews{
    height: 350px;
    width: 350px;
    background-color: #DDC7BB;
    margin-right: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-radius: 10px;
    display: inline-block;
    overflow:auto;
    transition: transform 1s ease-in-out;
    animation: slideAnimation 30s linear infinite;
}

.reviews Image{
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}