.footer {
    background-color: #DDC7BB;
    height: fit-content;
    padding-bottom: 1rem;
}

.footer_Links {
    text-decoration: none;
    color: #4F3527;
}

.footer li {
    list-style-type: none;
    text-align: start;
    margin-top: 1rem;
    font-weight: 700;
}

.footer h3 {
    text-align: start;
    margin-top: 2rem;
    color: #2B1B12;
    font-weight: 700;
    font-family: serif;
}

.footer p {
    margin-top: 2rem;
    text-align: start;
    font-weight: 700;
    color: #4F3527;
}

.footer ul {
    margin: 0px;
    padding: 0px;
}

.foot {
    background-color: #000000;
    height: 30px;
    color: #fff;
    text-align: center;
    font-weight: 600;
}