.Image-container{
    height: 500px;
    margin: auto;
    position: relative;
    width: 90%;
    display: grid;
    place-items: center;
    overflow: hidden;
}
.Image-slide-container{
    display: flex;
    width: calc(250px*44);
    animation: scroll 20s linear infinite;
}
.Image-slide-container:hover {
    animation-play-state: paused;
}
@keyframes scroll {
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(calc(-250px * 22));
    }
    
}
.slide-image-div{
    height: 250px;
    width: 500px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 25px;
    perspective: 100px;
}
.Image-Block{
    width: 100%;
    transition: transform 0.5s;
}
.Image-Block:hover{
    transform: translateZ(20px);
}